<template>
    <div :class="`card-visita cr-pointer  br-9 ${fechaFin ? '' : 'actual'}`">
        <div class="header text-white d-flex justify-content-around py-2 br-t-9">
            <p class="f-14 f-600 my-auto">Visita {{ id }}</p>
            <div class="d-middle">
                <i class="icon-calendar-today" />
                <p class="f-12 text-capitalize">
                    {{ fechaInicio | helper-fecha('DD MMM YYYY') }}  
                    <span v-if="fechaFin">- {{ fechaFin | helper-fecha('DD MMM YYYY') }}</span> 
                </p>
            </div>
            <div class="d-middle">
                <i class="icon-clock-time-eight-outline"></i>
                <p class="f-12">{{ fechaInicio | helper-fecha('h:mm a') }}</p>
            </div>
        </div>
        <div class="pt-1 px-3">
            <card-mini-user class=" my-2" :img="foto" tamanoImg="54" :nombre="nombre" :cargo="cargo" fw="f-600" />
            <div class="d-flex justify-content-between">
                <p class="f-12 logros my-auto">Logros de la visita:  <span class="f-600"> {{ totalVisitas }} actividades</span> </p>
                <div class="d-middle items-contadores">
                    <i class="icon-message-reply-text"></i>
                    <p class="f-12">{{ totalComentarios }}</p>
                    <i class="icon-paperclip"></i>
                    <p class="f-12"> {{ totalAdjuntos }} </p>
                </div>
            </div>
        </div>
        <div class="porcentaje-bar my-1">
            <div class="actual" :style="`width: ${porcentaje}%`"></div>
        </div>
        <div class="px-3 pb-1">
            <p class="f-14 f-600 text-porcentaje">{{ porcentaje }}%</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id:{
            type: Number,
            required: true
        },
        fechaInicio:{
            type: String,
            required: true
        },
        fechaFin:{
            type: String,
        },
        hora:{
            type: String,
            //required: true
        },
        nombre:{
            type: String,
        },
        foto:{
            type: String,
        },
        cargo:{
            type: String,
        },
        totalVisitas:{
            type: Number,
            required: true
        },
        totalComentarios:{
            type: Number,
        },
        totalAdjuntos:{
            type: Number,
        },
        porcentaje:{
            type: Number,
            required: true
        },
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
.card-visita{
    background: #F6F6F6;
    .header{
        background: #a5a5a5;
    }
    .porcentaje-bar{
        background: #dddddd;
        height: 4px;
        position: relative;
        .actual{
            position: absolute;
            background: #5e5e5e;
            height: 4px;
            z-index: 1;
        }
    }
    .br-9{
        border-radius: 9px;
    }
    .br-t-9{
        border-radius: 9px 9px 0px 0px;
    }
    .text-porcentaje{
        color: #A5A5A5;
    }
    &.actual{
        .header, .porcentaje-bar .actual{
            background: var(--color-general) !important;
        }
        .logros, .text-porcentaje{
            color:  var(--color-general) !important;
        }
    }
}
</style>