<template>
    <section class="section-servicios-visitas">
        <titulo-divisor titulo="Visitas realizadas" class="mb-4 mx-0">
            <button v-if="detalleServicio.porcentaje_avance < 100" class="btn btn-general text-white f-12 px-5" @click="openModalRegisterVisit">Registrar visita</button>
        </titulo-divisor>
        <div class="row">
            <div v-for="(data,idx) in visitas" :key="idx" class="col-lg-4 col-md-6 col-12 my-3" @click="verVisita(data)">
                <card-visita 
                :id="visitas.length - idx"
                :fechaInicio="data.fecha_hora_visita"
                :fechaFin="data.fecha_hora_final_visita"
                :nombre="data.nombre_usuario"
                :foto="data.foto"
                :cargo="data.cargo"
                :totalVisitas="data.actividades_terminadas"
                :totalComentarios="data.numero_comentarios"
                :totalAdjuntos="data.numero_adjuntos"
                :porcentaje="data.porcentaje_avance"
                 />
            </div>
        </div>
        <div v-if="visitas.length === 0" class="row bg-whitesmoke mx-0 py-5 br-5">
            <div class="col-12">
                <default-image 
                
                image="/img/default/no-visitas.svg"
                text="No se han registrado visitas"
                />
            </div>
        </div>
        <!-- partials -->
        <modal-register-visit ref="openModalRegisterVisit" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import cardVisita from './components/card-visita'
import modalRegisterVisit from './partials/modalRegisterVisit'
export default {
    components: {
        cardVisita,
        modalRegisterVisit,
    },
    computed: {
        ...mapGetters({
            detalleServicio: 'obras/servicios/lista_chequeo/detalleServicio',
            visitas: 'obras/servicios/visitas/visitas',
        }),
    },
    data() {
        return {
            key: '',
            id_proyecto: this.$route.params.id_proyecto,
            id_control_piso: this.$route.params.id_control_piso, 
        }
    },
    async mounted () {
        await this.Action_get_selects_encargados_users();
        await this.Action_get_servicios({id_proyecto:this.id_proyecto ,id_control_piso:this.id_control_piso});
        this.visitas.forEach(visita => localStorage.setItem(`${this.$route.path}${visita.id}/ver`, visita.numero))
    },
    methods: {
        ...mapActions({
            Action_get_selects_encargados_users: 'selects/selects/Action_get_selects_encargados_users',
            Action_get_servicios: 'obras/servicios/visitas/Action_get_servicios',
        }),
        openModalRegisterVisit() {
            this.$refs.openModalRegisterVisit.toggle()
        },
        verVisita(data) {
            this.$router.push({ name: 'obras.vigentes.servicios.visitas.ver', params: { id_visita: data.id } })
        }
    },
}
</script>

<style>

</style>